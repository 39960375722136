import { Link } from "gatsby"
import React from "react"
import { TenjoLogoImage } from "../image"

const FOOTER_MENU = [
  {
    heading: "Projects",
    children: [
      {
        name: "Pulo Waringin",
        to: "/projects/pulo-waringin"
      },
      {
        name: "Sentra Cimanggis",
        to: "/projects/sentra-cimanggis"
      },
      {
        name: "Sentra Danau Kemuning",
        to: "/projects/sentra-danau-kemuning"
      },
      {
        name: "Sentra Pondok Rajeg",
        to: "/projects/sentra-pondok-rajeg"
      },
      {
        name: "Sentra Tajur Halang",
        to: "/projects/sentra-tajur-halang"
      },
      {
        name: "Sentra Waringin",
        to: "/projects/sentra-waringin"
      },
      {
        name: "Tenjo City Metropolis",
        to: "/projects/tenjo-city-metropolis"
      },
      {
        name: "Tenjo City Residence",
        to: "/projects/tenjo-city-residence"
      }
    ]
  },
  {
    heading: "Links",
    children: [
      {
        name: "About Us",
        to: "/about-us"
      },
      {
        name: "Gallery",
        to: "/gallery"
      },
      {
        name: "News",
        to: "/news"
      },
      {
        name: "Contact Us",
        to: "/contact-us"
      }
    ]
  },
  {
    heading: "Location",
    children: [
      {
        name: "Jln Kampung Baru Kel.Singabangsa, Kec. Tenjo, Bogor, West Java 16370"
      },
      {
        name: "0898 620 4907 (Sylvia) | 0813 1011 1172 (Admin Tenjo City) | 0813 8186 8338 (Sutanto)"
      }
    ]
  }
]

const Footer = () => {
  const renderFooterMenus = () => FOOTER_MENU.map((menu, idx) => (
    <div className="footer-menuWrapper" key={idx}>
      <h4 className="footer-menuHeading">{menu.heading}</h4>
      {menu.children.map((child, index) => {
          if (child.to) {
            return (
              <Link
                key={index}
                className="footer-menuLink"
                to={child.to}
              >
                {child.name}
              </Link>
            )
          } else {
            return (
              <p
                className="footer-menuLink"
                key={index}
              >
                {child.name}
              </p>
            )
          }
        }
      )}
    </div>
  ))

  return (
    <footer>

      <div className="footer-grid">
        <div className="footer-logoWrapper">
          <div className="footer-logo">
            <Link to="/">
              <TenjoLogoImage />
            </Link>
          </div>
        </div>
        {renderFooterMenus()}
      </div>

      <div className="footer-copyrightWrapper">
        <div className="footer-copyright">
          © 2020 by Tenjo Metropolis
        </div>
      </div>

    </footer>
  )
}

export default Footer
