import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Close from "../../images/icons/close.svg"
import Footer from "./footer"
import Header from "./header"
import "./index.scss"
// react-slick css
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const NAV_MENU = [
  {
    name: "Home",
    to: "/"
  },
  {
    name: "Projects",
    to: "/projects"
  },
  {
    name: "About Us",
    to: "/about-us"
  },
  {
    name: "Gallery",
    to: "/gallery"
  },
  {
    name: "News",
    to: "/news"
  },
  {
    name: "Contact Us",
    to: "/contact-us"
  }
]

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [showMobileNav, setShowMobileNav] = useState(false)

  const toggleMobileNav = (shouldShow) => {
    setShowMobileNav(shouldShow)
  }

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        menus={NAV_MENU.slice(1)}
        onShowMobileNav={() => toggleMobileNav(true)}
      />
      <main>{children}</main>
      <Footer />
      {showMobileNav && (
        <div className="mobile-nav">
          <Close
            className="mobile-navClose"
            onClick={() => toggleMobileNav(false)}
          />
          <div className="mobile-navMenu">
            {
              NAV_MENU.map((menu) => (
                <Link
                  className="mobile-navMenuItem"
                  to={menu.to}
                  key={menu.name}
                  onClick={() => toggleMobileNav(false)}
                  activeClassName="is-active"
                >
                  {menu.name}
                </Link>
              ))
            }
          </div>
        </div>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
