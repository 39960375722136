import React from "react"
import { Link } from "gatsby"
import { TenjoLogoImage } from "../image"
import Burger from "../../images/icons/burger.svg"
import "./index.scss"

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? { className: "menu-item is-active" }
    : {}
}

const Header = ({ menus, onShowMobileNav }) => {
  const renderMenus = () => menus.map((menu) => (
    <Link
      className="menu-item"
      to={menu.to}
      key={menu.name}
      activeClassName="is-active"
      getProps={isPartiallyActive}
    >
      {menu.name}
    </Link>
  ))

  return (
    <div className="header">
      <Burger
        className="header-burger"
        onClick={onShowMobileNav}
      />
      <div className="header-logo">
        <Link to="/">
          <TenjoLogoImage />
        </Link>
      </div>
      {renderMenus()}
    </div>
  )
}

export default Header
